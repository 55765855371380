import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: { name: "admin.login" },
      // redirect: { name: "check.authentication" },
    },
    /*{
      path: "/authenticate",
      name: "check.authentication",
      component: () => import("@/view/pages/Authentication"),
    },
    {
      path: "/admin",
      name: "admin",
      redirect: { name: "admin.dashboard" },
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "dashboard",
          name: "admin.dashboard",
          component: () => import("@/view/pages/Dashboard"),
          meta: {
            requiresAuth: true,
            menuActive: "dashboard",
            title: "Dashboard",
          },
        },
        {
          path: "profile",
          name: "admin.profile",
          redirect: { name: "admin.profile.basic" },
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "basic",
              name: "admin.profile.basic",
              component: () => import("@/view/pages/profile/AdminBasic.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "profile",
                title: "Profile",
              },
            },
            {
              path: "setting",
              name: "admin.profile.setting",
              component: () => import("@/view/pages/profile/Setting.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "setting",
                title: "Setting",
              },
            },
            {
              path: "activity",
              name: "admin.profile.activity",
              component: () => import("@/view/pages/profile/Activity.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "activity",
                title: "Activity",
              },
            },
            {
              path: "reminders",
              name: "admin.profile.reminders",
              component: () => import("@/view/pages/profile/Reminder.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "reminders",
                title: "Reminder",
              },
            },
          ],
        },
        {
          path: "bug-reports",
          name: "admin.bug.report",
          component: () => import("@/view/pages/bug-report/Bug-Report.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            title: "Bug Report",
          },
        },
        {
          path: "bug-reports/create",
          name: "admin.create.bug.report",
          component: () =>
            import("@/view/pages/bug-report/Create-Bug-Report.vue"),
          meta: {
            requiresAuth: true,
            title: "Create Bug Report",
          },
        },
        {
          path: "bug-reports/detail/:id",
          name: "admin.detail.bug.report",
          component: () =>
            import("@/view/pages/bug-report/Detail-Bug-Report.vue"),
          meta: {
            requiresAuth: true,
            title: "Detail Bug Report",
          },
        },
        {
          path: "email-template/update/:id",
          name: "admin.email.template.update",
          component: () =>
            import("@/view/pages/profile/Setting/CreateEmailTemplate.vue"),
          meta: {
            requiresAuth: true,
            title: "Update Email Template",
          },
        },
        {
          path: "role/create",
          name: "admin.role.create",
          component: () =>
            import("@/view/pages/profile/Setting/CreateRole.vue"),
          meta: {
            requiresAuth: true,
            title: "Create Role",
          },
        },
        {
          path: "role/update/:id",
          name: "admin.role.update",
          component: () =>
            import("@/view/pages/profile/Setting/CreateRole.vue"),
          meta: {
            requiresAuth: true,
            title: "Update Role",
          },
        },
        {
          path: "users",
          name: "admin.profile.users",
          component: () => import("@/view/pages/profile/Users.vue"),
          meta: {
            requiresAuth: true,
            title: "User",
          },
        },
        {
          path: "users/permission/:id",
          name: "admin.profile.userpermission",
          component: () => import("@/view/pages/profile/UserPermission.vue"),
          meta: {
            requiresAuth: true,
            title: "Users Permission",
          },
        },
        {
          path: "calendar",
          name: "admin.calendar",
          component: () => import("@/view/pages/Calendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Calendar",
          },
        },
        {
          path: "calendar/resource",
          name: "admin.calendar.resource",
          component: () => import("@/view/pages/ResourceCalendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Resource Calendar",
          },
        },
        {
          path: "calendar/resource-h",
          name: "admin.calendar.resource-h",
          component: () => import("@/view/pages/ResourceCalendarHorizontalLT1"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Resource Calendar",
          },
        },
        {
          path: "calendar/map",
          name: "admin.calendar.map",
          component: () => import("@/view/pages/Map"),
          meta: {
            requiresAuth: true,
            menuActive: "map",
            title: "Map",
          },
        },
        {
          path: "customer",
          name: "admin.customer",
          component: () => import("@/view/pages/customer/Customer"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "customer",
            title: "Customer",
          },
        },
        {
          path: "customer/create",
          name: "admin.customer.create",
          component: () => import("@/view/pages/customer/Create-Customer"),
          meta: {
            requiresAuth: true,
            menuActive: "customer",
            title: "Create | Customer",
          },
        },
        {
          path: "customer/detail/:id",
          name: "admin.customer.detail",
          component: () => import("@/view/pages/customer/Detail-Customer"),
          meta: {
            requiresAuth: true,
            menuActive: "customer",
            title: "Detail | Customer",
          },
        },
        {
          path: "quotation",
          name: "admin.quotation",
          component: () => import("@/view/pages/quotation/Quotation"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "quotation",
            title: "Quotation",
          },
        },
        {
          path: "quotation/create",
          name: "admin.quotation.create",
          component: () => import("@/view/pages/quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Create | Quotation",
          },
        },
        {
          path: "quotation/update/:id",
          name: "admin.quotation.update",
          component: () => import("@/view/pages/quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Update | Quotation",
          },
        },
        {
          path: "quotation/detail/:id",
          name: "admin.quotation.detail",
          component: () => import("@/view/pages/quotation/Detail-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Detail | Quotation",
          },
        },
        {
          path: "contract",
          name: "admin.contract",
          component: () => import("@/view/pages/contract/Contract"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "contract",
            title: "Contract",
          },
        },
        {
          path: "contract/create",
          name: "admin.contract.create",
          component: () => import("@/view/pages/contract/Create-Contract"),
          meta: {
            requiresAuth: true,
            menuActive: "contract",
            title: "Create | Contract",
          },
        },
        {
          path: "contract/update/:id",
          name: "admin.contract.update",
          component: () => import("@/view/pages/contract/Create-Contract"),
          meta: {
            requiresAuth: true,
            menuActive: "contract",
            title: "Update | Contract",
          },
        },
        {
          path: "contract/detail/:id",
          name: "admin.contract.detail",
          component: () => import("@/view/pages/contract/Detail-Contract"),
          meta: {
            requiresAuth: true,
            menuActive: "contract",
            title: "Detail | Contract",
          },
        },
        {
          path: "user",
          name: "admin.user",
          component: () => import("@/view/pages/user/User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "User",
          },
        },
        {
          path: "user/create",
          name: "admin.user.create",
          component: () => import("@/view/pages/user/Create-User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "Create | User",
          },
        },
        {
          path: "user/detail/:id",
          name: "admin.user.detail",
          component: () => import("@/view/pages/user/Detail-User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "Detail | User",
          },
        },
        {
          path: "property",
          name: "admin.property",
          component: () => import("@/view/pages/property/Property"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "property",
            title: "Property",
          },
        },
        {
          path: "property/create",
          name: "admin.property.create",
          component: () => import("@/view/pages/property/Create-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Create | Property",
          },
        },
        {
          path: "property/detail/:id",
          name: "admin.property.detail",
          component: () => import("@/view/pages/property/Detail-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Detail | Property",
          },
        },
        {
          path: "property/update/:id",
          name: "admin.property.update",
          component: () => import("@/view/pages/property/Create-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Update | Property",
          },
        },
        {
          path: "invoice",
          name: "admin.invoice",
          component: () => import("@/view/pages/invoice/Invoice"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "invoice",
            title: "Invoice",
          },
        },
        {
          path: "invoice/create",
          name: "admin.invoice.create",
          component: () => import("@/view/pages/invoice/Create-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Create | Invoice",
          },
        },
        {
          path: "invoice/update/:id",
          name: "admin.invoice.update",
          component: () => import("@/view/pages/invoice/Create-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Update | Invoice",
          },
        },
        {
          path: "invoice/detail/:id",
          name: "admin.invoice.detail",
          component: () => import("@/view/pages/invoice/Detail-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Detail | Invoice",
          },
        },
        {
          path: "payment",
          name: "admin.payment",
          component: () => import("@/view/pages/payment/Payment"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "payment",
            title: "Payment",
          },
        },
        {
          path: "payment/detail/:id",
          name: "admin.payment.detail",
          component: () => import("@/view/pages/payment/Detail-Payment"),
          meta: {
            requiresAuth: true,
            menuActive: "payment",
            title: "Detail | Payment",
          },
        },
        {
          path: "product",
          name: "admin.product",
          component: () => import("@/view/pages/product/Product"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "product",
            title: "Product",
          },
        },
        {
          path: "product/create",
          name: "admin.product.create",
          component: () => import("@/view/pages/product/Create-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Create | Product",
          },
        },
        {
          path: "product/update/:id",
          name: "admin.product.update",
          component: () => import("@/view/pages/product/Create-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Update | Product",
          },
        },
        {
          path: "product/detail/:id",
          name: "admin.product.detail",
          component: () => import("@/view/pages/product/Detail-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Detail | Product",
          },
        },
        {
          path: "product/adjustment/create",
          name: "admin.product.adjustment.create",
          component: () =>
            import("@/view/pages/product/adjustment/Create-Adjustment"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Create | Product Adjustment",
          },
        },
        {
          path: "service",
          name: "admin.service",
          component: () => import("@/view/pages/service/Service"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "service",
            title: "Service",
          },
        },
        {
          path: "service/create",
          name: "admin.service.create",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Create | Service",
          },
        },
        {
          path: "service/update/:id",
          name: "admin.service.update",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Update | Service",
          },
        },
        {
          path: "service/detail/:id",
          name: "admin.service.detail",
          component: () => import("@/view/pages/service/Detail-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Detail | Service",
          },
        },
        {
          path: "job/create",
          name: "admin.job.create",
          component: () => import("@/view/pages/job/Create-Job"),
          meta: {
            requiresAuth: true,
            menuActive: "visit",
            title: "Create | Visit",
          },
        },
        {
          path: "visit",
          name: "admin.visit",
          component: () => import("@/view/pages/visit/Visit"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "visit",
            title: "Visit",
          },
        },
        {
          path: "visit/detail/:id",
          name: "admin.visit.detail",
          component: () => import("@/view/pages/visit/Visit-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "visit",
            title: "Visit Detail",
          },
        },
        {
          path: "follow-up",
          name: "admin.follow-up",
          component: () => import("@/view/pages/follow-up/Follow-ups"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "follow-up",
            title: "Follow-ups",
          },
        },
        {
          path: "visit/detail/:id",
          name: "admin.visit.detail",
          component: () => import("@/view/pages/visit/Visit-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "follow-up",
            title: "Visit Detail",
          },
        },
        {
          path: "engineer",
          name: "admin.engineer",
          component: () => import("@/view/pages/engineer/Engineer"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "engineer",
            title: "Staff",
          },
        },
        {
          path: "engineer/create",
          name: "admin.engineer.create",
          component: () => import("@/view/pages/engineer/Create-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Create | Engineer",
          },
        },
        {
          path: "engineer/update/:id",
          name: "admin.engineer.update",
          component: () => import("@/view/pages/engineer/Create-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Update | Engineer",
          },
        },
        {
          path: "engineer/detail/:id",
          name: "admin.engineer.detail",
          component: () => import("@/view/pages/engineer/Detail-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Detail | Engineer",
          },
        },
        {
          path: "warranty",
          name: "admin.warranty",
          component: () => import("@/view/pages/warranty/Warranty"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "warranty",
            title: "Warranty",
          },
        },
        {
          path: "report",
          name: "admin.report",
          component: () => import("@/view/pages/report/Report"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "report",
            title: "Report",
          },
        },
        {
          path: "report/:module",
          name: "admin.report.detail",
          component: () => import("@/view/pages/report/Report-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "report",
            title: "Report Detail",
          },
        },
      ],
    },*/
    {
      path: "/admin/login",
      name: "admin.login",
      component: () => import("@/view/pages/auth/Login"),
      meta: { requiresAuth: false, title: "Admin | Login" },
    },
    /*{
      path: "/customer/quotation/approve",
      name: "customer.quotation.approve",
      component: () => import("@/view/customer/quotation/Approve"),
      meta: { requiresAuth: false, title: "Approve | Quotation | Customer" },
    },
    {
      path: "/customer/contract/approve",
      name: "customer.contract.approve",
      component: () => import("@/view/customer/contract/Approve"),
      meta: { requiresAuth: false, title: "Approve | Contract | Customer" },
    },*/
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1"),
      meta: { requiresAuth: false, title: "Page Not Found" },
    },
  ],
});
