import ApiService from "@/core/services/api.service";

export const POST = "post";
export const UPLOAD = "upload";
export const DOWNLOAD = "download";
export const GET = "get";
export const QUERY = "query";
export const PUT = "put";
export const PATCH = "patch";
export const DELETE = "delete";
export const PURGE_PREVIOUS = "purgePrevious";
export const SET_TOP_LOADER = "setTopLoader";
export const CLEAR_ERROR = "clearError";
export const UPDATE_ERROR = "updateError";

export const VERIFY_QUOTATION_CUSTOMER = "verifyQuotationCustomer";
export const VERIFY_CONTRACT_CUSTOMER = "verifyContractCustomer";

export const SET_ERROR = "setError";
export const SET_MESSAGE = "setMessage";
export const SET_DATA = "setData";
export const PURGE_DATA = "purgeData";

const state = {
  errors: {},
  message: {},
  topLoader: {},
};

const getters = {
  topLoader(state) {
    return state.topLoader;
  },
};

const commitErrors = (response) => {
  let requestErrors = [];
  if (response && response.status === 422) {
    for (let error in response.data) {
      if (response.data[error]) {
        for (let i = response.data[error].length - 1; i >= 0; i--) {
          requestErrors.push(response.data[error][i]);
        }
      }
    }
  } else if (response && response.status === 401) {
    requestErrors.push(response.data.error);
  } else if (response && response.status === 500) {
    requestErrors.push(response.data.message);
  } else if (response && response.status === 400) {
    requestErrors.push(response.data.message);
  } else if (response && response.status === 404) {
    requestErrors.push(response.data.message);
  } else if (response && response.status === 405) {
    requestErrors.push(response.data.message);
  } else {
    requestErrors.push(response.toString());
  }
  return requestErrors;
};

const actions = {
  [VERIFY_QUOTATION_CUSTOMER](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.setCustomHeader(request.token);
      ApiService.post(request.url, request.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_CONTRACT_CUSTOMER](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.setCustomHeader(request.token);
      ApiService.post(request.url, request.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [POST](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(request.url, request.data)
        .then(({ data }) => {
          if (data.message) {
            context.commit(SET_MESSAGE, data.message);
          }
          context.commit(SET_DATA, data);
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [UPLOAD](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.upload(request.url, request.data)
        .then(({ data }) => {
          if (data.message) {
            context.commit(SET_MESSAGE, data.message);
          }
          context.commit(SET_DATA, data);
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [DOWNLOAD](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.download(request.url, request.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(request.url, request.data)
        .then(({ data }) => {
          context.commit(SET_DATA, data);
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [QUERY](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.query(request.url, request.data)
        .then(({ data }) => {
          context.commit(SET_DATA, data);
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [PUT](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.put(request.url, request.data)
        .then(({ data }) => {
          if (data.message) {
            context.commit(SET_MESSAGE, data.message);
          }
          context.commit(SET_DATA, data);
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [PATCH](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.patch(request.url, request.data)
        .then(({ data }) => {
          if (data.message) {
            context.commit(SET_MESSAGE, data.message);
          }
          context.commit(SET_DATA, data);
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [DELETE](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete(request.url, request.data)
        .then(({ data }) => {
          context.commit(SET_DATA, data);
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [PURGE_PREVIOUS](context) {
    return new Promise((resolve) => {
      context.commit(PURGE_DATA);
      resolve(true);
    });
  },
  [CLEAR_ERROR](context) {
    return new Promise((resolve) => {
      context.commit(SET_ERROR, []);
      resolve(true);
    });
  },
  [UPDATE_ERROR](context, error) {
    return new Promise((resolve) => {
      context.commit(SET_ERROR, commitErrors(error));
      resolve(true);
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_MESSAGE](state, data) {
    state.message = data;
  },
  [SET_TOP_LOADER](state, data) {
    state.topLoader = data;
  },
  [SET_DATA](state, data) {
    state.data = data;
    state.errors = {};
    state.message = {};
  },
  [PURGE_DATA](state) {
    state.data = {};
    state.errors = {};
    state.message = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
